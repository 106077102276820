<template>
    <div class="p-2" v-if="user">
        
        <!-- LEARN MORE -->
        <h2 class="text-center text-white font-weight-bolder my-2">Notice Board</h2>
        <!-- <div class="">
            <swiper :options="swiperOptions" class="w-100">
                <swiper-slide v-for="(data, index) in learn_more_banner" :key="index" class="rounded swiper-shadow">
                    <img style="height:200px;object-fit: cover;" class="rounded w-100" :src="data.url" />
                </swiper-slide>
                <div slot="button-next" class="swiper-button-next d-flex"><i
                        class="m-auto bx bx-chevron-right text-dark m-auto" style="font-size:28px"></i></div>
                <div slot="button-prev" class="swiper-button-prev d-flex"><i
                        class="m-auto bx bx-chevron-left text-dark m-auto" style="font-size:28px"></i></div>
            </swiper>
        </div> -->
        <div>
            <!-- <b-carousel id="carousel-1" :interval="4000" indicators>
                <b-carousel-slide v-for="item in banners" :img-src="item.banner_picture"></b-carousel-slide>
            </b-carousel> -->
            <swiper :options="swiperOptionsBanner" class="w-100">
                <swiper-slide v-for="item in banners" :key="index" class="rounded swiper-shadow">
                    <img style="height:200px;object-fit: cover;" class="rounded w-100" :src="item.banner_picture" />
                </swiper-slide> 
            </swiper>
        </div>
        <!-- END LEARN MORE -->

        <h2 class="text-center text-white font-weight-bolder mb-2 mt-3">Select Your Bussiness Category</h2>


        <div class="d-flex flex-wrap justify-content-center" style="gap:10px"> 
            <div class="d-flex" 
            :style="`background-color: #686569;height: 80px;width:80px;border-radius: 10px;`">
                <h5 class="mb-1 mx-auto mt-auto" :style="`color: white;`">Groomer</h5>
            </div>
            <div class="d-flex" 
            :style="`background-color: #686569;height: 80px;width:80px;border-radius: 10px;`">
                <h5 class="mb-1 mx-auto mt-auto" :style="`color: white;`">Pet Shop</h5>
            </div>
            <!-- <router-link :to="user.store_data ? `/bussiness/shop` : '/store-register'" class="d-flex" 
            :style="`background-color: ${user.store_data ? '#1B628C' : '#D9D9D9'};height: 80px;width:80px;border-radius: 10px;`">
                <h5 class="mb-1 mx-auto mt-auto" :style="`color: ${user.store_data ? '#FFAD32' : '#005071'};`">Pet Shop</h5>
            </router-link> -->
            <router-link :to="user.trainer ? `/bussiness/training` : '/trainer-register'" class="d-flex" 
                :style="`background-color: ${user.trainer ? '#1B628C' : '#D9D9D9'};height: 80px;width:80px;border-radius: 10px;`">
                <h5 class="mb-1 mx-auto mt-auto font-weight-bolder" :style="`color: ${user.trainer ? '#FFAD32' : '#005071'};`">Trainer</h5>
            </router-link>
            
            <router-link :to="user.walker ? `/bussiness/walker/${user.user_id}` : '/walker-register'" class="d-flex" 
                :style="`background-color: ${user.walker ? '#1B628C' : '#D9D9D9'};height: 80px;width:80px;border-radius: 10px;`">
                <h5 class="mb-1 mx-auto mt-auto font-weight-bolder" :style="`color: ${user.walker ? '#FFAD32' : '#005071'};`">Pet Sitting</h5>
            </router-link>

            <div class="d-flex" 
            :style="`background-color: #686569;height: 80px;width:80px;border-radius: 10px;`">
                <h5 class="mb-1 mx-auto mt-auto" :style="`color: white;`">Hotel</h5>
            </div>
            
            <router-link :to="user.shelter ? `/shelter/profile/${user.shelter.shelter_id}` : '/shelter-register'" class="d-flex" 
                :style="`background-color: ${user.shelter ? '#1B628C' : '#D9D9D9'};height: 80px;width:80px;border-radius: 10px;`">
                <h5 class="mb-1 mx-auto mt-auto" :style="`color: ${user.shelter ? '#FFAD32' : '#005071'};`">Shelter</h5>
            </router-link> 
            
            <router-link :to="user.vet ? `/vet/profile/${user.vet.vet_id}` : '/vet-register'" class="d-flex" 
                :style="`background-color: ${user.vet ? '#1B628C' : '#D9D9D9'};height: 80px;width:80px;border-radius: 10px;`">
                <h5 class="mb-1 mx-auto mt-auto" :style="`color: ${user.vet ? '#FFAD32' : '#005071'};`">Vet</h5>
            </router-link>
            
            <div class="d-flex" 
            :style="`background-color: #686569;height: 80px;width:80px;border-radius: 10px;`">
                <h5 class="mb-1 mx-auto mt-auto" :style="`color: white;`">Crematory</h5>
            </div>
            
            <div class="d-flex" 
            :style="`background-color: #686569;height: 80px;width:80px;border-radius: 10px;`">
                <h5 class="mb-1 mx-auto mt-auto" :style="`color: white;`">Pet Taxi</h5>
            </div>
        </div>
        
    </div>
</template>

<script>

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { BCarousel, BCarouselSlide } from 'bootstrap-vue'

import store from '@/store'

export default {
    computed: {
        user(){
            return store.state.auth.USER
        },
        banners() {
            return store.state.banners.BANNERS || []
        },
    }, 
    mounted(){
        store.dispatch('banners/GetBanners')
    },
    components: {
        Swiper, SwiperSlide,
        BCarousel, BCarouselSlide
    },
    data() {
        return {
            learn_more_banner: [
                { url: 'https://d1csarkz8obe9u.cloudfront.net/posterpreviews/pet-shop-cover-template-design-7fbcea6de5ed45af0716f61106314548_screen.jpg?ts=1622085870' },
                { url: 'https://d1csarkz8obe9u.cloudfront.net/posterpreviews/pet-shop-banner-design-template-4c29604da3e3601ec0a5d464e2cbeb4b_screen.jpg?ts=1621925071' }
            ],
            swiperOptions: {
                slidesPerView: 1,
                spaceBetween: 10,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
            },
            swiperOptionsBanner: {
                slidesPerView: 1,
                spaceBetween: 10, 
                autoplay: {
                    delay: 3000,
                },
            },
        }
    }
}

</script>

<style>
 .swiper-button-prev,
 .swiper-button-next {
     --swiper-theme-color: transparent;
     background: #ffffff;
     box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.2);
     width: 40px;
     height: 40px;
     border-radius: 50%;
     padding: 0 !important;
 }

 .swiper-button-prev::after,
 .swiper-button-next::after {
     content: unset !important;
 }
</style> 